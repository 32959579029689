<template>
  <div style="background: #fff; height: 100%;">
    <van-nav-bar title="收银台" left-arrow :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="pt">
      <div class="fkname"><span class="fg">付款给：</span><span>{{ garageName }}</span></div>
      <img class="jin" src="../../../assets/img/jin.png" alt="">
      <div class="jiner">
        ￥{{ amount ?Number(amount).toFixed(2) : '0.00' }}
      </div>
    </div>
    <div class="heng"></div>
    <div class="bot">
      <van-button class="bost" type="danger" @click="pay" size="small">付款</van-button>
    </div>
  </div>
</template>
  
<script>
import { cbbsourceaccount } from "@/api/check";
export default {
  data() {
    return {
      list: [], //
      gid: this.$route.query.gid,
      accountNumber: this.$route.query.accountNumber,
      amount: this.$route.query.amount,
      garageName: this.$route.query.garageName,
      key: "",
      page: 0,
      size: 20,
      loading: false,
      total: 0,
      finished: false,
      isiOS: false,
    };
  },
  methods: {
 

    pay() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        amount: this.amount,
        // accountNumber: 0.01,
        accountNumber: this.accountNumber,
      };
      cbbsourceaccount(data).then((e) => {
        loading.clear();
        if (e.code == 200) {
          console.log(e)
          window.location.href = e.data.qrurl;

        }
      });
    },

    back() {
      this.$router.go(-1);
    },
  },
  created() {
    // localStorage.setItem("Authorization", 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIzNDk0IiwiZXhwIjoxNjkwNDI1OTk4fQ.VBZfOwIBjDxY4apoXJhXFhPcwSrrIpUhd4dw4eQ5yn62KwPNeMPf_FtSiuhmk5j1NfDcu5bSZLvlffVYiUqiTw');
    // this.getlist();
    //判断是否在微信环境

  },
  mounted() { },
  computed: {},
};
</script>
<style>

</style>
<style lang="less" scoped>
.pt {
  padding-top: 46px;
  
  .fg {
    font-size: 14px;
  }

  .fkname {
    text-align: center;
    font-size: 18px;
    padding-top: 30px;
  }

  .jin {
    display: block;
    margin: auto;
    width: 40px;
    margin-top: 30px;
  }

  .jiner {
    text-align: center;
    font-size: 28px;
    padding-top: 10px;
  }
}
.heng{
    height: 10px;
    width: 100%;
    background: #f6f6f6;
    margin-top: 40px;
  }
.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
</style>

  
   